<script>
import TablePage from '../../../../../components/table_page';
import Form from './form.vue';
import * as utils from '../../../utils/index';

export default {
  name: 'table-component',
  extends: TablePage,
  components: { Form },
  data() {
    return {
      requestUrl: '/sfa/SfaAchievementRuleController/list',
      formConfig: {},
      /** @desc 搜索框绑定数据 */
      formData: {},
      searchFormData: {},
    };
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'add') this.btnAdd();
      if (val.code === 'edit') this.btnEdit(row);
      if (val.code === 'view') this.btnCheck(row);
    },
    /** @desc 点击查看 */
    cellClick({ row, column }) {
      if (column.property === 'objectCode_1') { // 对象编码 取消点击查看
        this.formName = 'Form';
        this.formConfig = {
          type: 'view',
          disabled: true,
          row,
          id: row.id,
        };
        this.modalConfig.title = '查看详情';
        this.openFull();
      }
    },
    /** @desc 新增 */
    btnAdd() {
      this.formName = 'Form';
      this.formConfig.row = {};
      this.formConfig.disabled = false;
      this.modalConfig.title = '新增';
      this.openFull();
    },
    /** @desc 编辑 */
    btnEdit(row) {
      this.formName = 'Form';
      this.formConfig.row = { ...row };
      this.formConfig.disabled = false;
      this.modalConfig.title = '编辑';
      this.openFull();
    },
  },
  created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    this.getConfigList('sfaCenter_performanceMange_performace_target_report');
  },

};
</script>
