<template>
      <el-form-item>
        <el-select
            v-model="objectCode"
            filterable
            remote
            :remote-method="getOrgList"
            @change="handleChange"
            clearabl
            :loading="loading"
            :disabled="disabled"
            placeholder="请输入组织名称模糊搜索">
            <el-option
              v-for="(item, index) in orgNameList"
              :key="index"
              :label="item.orgName"
              :value="item.objectCode"
            ></el-option>
          </el-select>
    </el-form-item>
</template>
<script>
import request from '../../../../../../utils/request';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
    },
  },
  watch: {
    value: {
      handler(val) {
        if (this.disabled) {
          this.objectCode = val.objectName;
        }
      },
    },
  },
  data() {
    return {
      // 组织编码
      objectCode: '',
      // 组织名称
      OrgName: '',
      // 组织列表
      orgNameList: [],
      loading: false,
    };
  },
  methods: {
    getOrgList(query) {
      this.loading = true;
      // 远程搜索
      request.post('/mdm/mdmOrgController/findOrgSelectList', {
        orgName: query,
      }, { headers: { functionCode: 'mdmOrgController_findOrgSelectList' } }).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.orgNameList = res.result.map((item) => ({
            objectCode: item.orgCode,
            orgName: item.orgName,
          }));
        } else {
          this.$message.error(res.$message.error(res.message));
        }
      });
    },
    handleChange(val) {
      this.orgNameList.forEach((item) => {
        if (item.objectCode === val) {
          this.OrgName = item.orgName;
        }
      });
      this.$emit('input', {
        objectCode: this.objectCode, // 对象编码
        objectName: this.OrgName, // 对象名称
        objectPosName: '', // 职位名称
      });
    },
  },
  mounted() {
    this.getOrgList();
  },
};
</script>
<style lang="less" scoped>
  .org-name {
    width: 100%;
  }
</style>
