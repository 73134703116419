<template>
    <div class="user-info">
      <!--  -->
      <el-row
        v-for="(item, index) in userInfo"
        :key="index"
      ><el-col :md="10">
          <el-form-item label="人员姓名" required>
            <el-select
              @change="handleChange(index)"
              remote
              filterable
              :remote-method="query => getUserList(query, 'search')"
              :loading="loading"
              placeholder="请输入人员姓名模糊搜索"
              @focus="getUserList('')"
              v-model="userInfo[index].objectName">
              <el-option
                v-for="(item, index) in userList"
                :key="index"
                :label="item.objectName"
                :value="item.objectName"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="10">
          <el-form-item label="人员职位">
            <el-input
              v-model="userInfo[index].objectPosName"
              disabled
              class="position-name"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="1" :offset="1" class="add-item">
          <i v-if="index===0" class="el-icon-circle-plus-outline" @click="addItem"></i>
          <i v-else class="el-icon-remove-outline" @click="delItem(index)"></i>
        </el-col>
      </el-row>
    </div>
</template>
<script>
import request from '../../../../../../utils/request';

export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    value: {
      immediate: true, // fix: #60915
      handler(val) {
        if (val && Array.isArray(val)) {
          this.userInfo = val;
        }
      },
    },
  },
  data() {
    return {
      // 人员信息表单
      userInfo: [
        {
          // 对象编码 对应字段 mdm/mdmPositionController/positionSelectList 返回的 userName
          objectCode: '',
          // 人员姓名
          objectName: '',
          // 人员职位
          objectPosName: '',
          // 人员职位编码
          objectPosCode: '',
        },
      ],
      // 当前选中人员的索引
      // index: 0,
      // 人员信息
      userList: [],
      // 是否加载
      loading: false,
    };
  },
  methods: {
    // 添加选项
    addItem() {
      this.userInfo.push({
        objectCode: '',
        objectName: '',
        objectPosName: '',
        objectPosCode: '',
      });
    },
    // 删除选项
    delItem(index) {
      this.userInfo.splice(index, 1);
    },
    // 选择人员后
    handleChange(index) {
      const res = this.userList.find((item) => item.objectName === this.userInfo[index].objectName);
      // 为人员编码赋值
      this.userInfo[index].objectCode = res.objectCode;
      // 对象名称
      this.userInfo[index].objectName = res.objectName;
      // 给人员职位赋值
      this.userInfo[index].objectPosName = res.objectPosName;
      // 给人员职位编码
      this.userInfo[index].objectPosCode = res.objectPosCode;
      this.$emit('input', this.userInfo);
    },
    // 远程搜索人员
    getUserList(query, type) {
      const requestUrl = '/mdm/mdmPositionController/positionSelectList';
      this.loading = true;
      if (type === 'init' || query === '') { // 搜索条件为空，搜索所有的人员
        request.post(requestUrl, {}, { headers: { functionCode: 'select-position' } }).then((res) => {
          this.loading = false;
          this.userList = [];
          res.result.forEach((item) => {
            if (item.fullName) { // 过滤空Name
              this.userList.push({
                objectCode: item.userName, // 后端： 对象编码是 userName :(
                objectName: item.fullName, // 对象名称
                objectPosName: item.positionName, // 职位名称
                objectPosCode: item.positionCode, // 职位编码
              });
            }
          });
        });
      } else if (query !== '') {
        request.post(requestUrl, {
          unionName: query,
        }, { headers: { functionCode: 'select-position' } }).then((res) => {
          this.loading = false;
          this.userList = [];
          res.result.forEach((item) => {
            if (item.fullName) { // 过滤空Name
              this.userList.push({
                objectCode: item.userName, // 后端： 对象编码是 userName :(
                objectName: item.fullName, // 对象名称
                objectPosName: item.positionName,
                objectPosCode: item.positionCode, // 职位编码
              });
            }
          });
        });
      }
    },
  },
  created() {
    this.getUserList('', 'init');
  },
};
</script>
<style lang="less" scoped>
  .user-info {
    /deep/ .el-form-item__label {
      font-size: 12px;
    }
  }
  .el-col {
    .el-icon-remove-outline {
      margin-left: -30px;
      color: #F56C6C;
      font-size: 24px;
    }
  .el-icon-circle-plus-outline {
      margin-left: -30px;
      color: #409EFF;
      font-size: 24px;
    }
  }

</style>
