var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    [
      _c(
        "el-select",
        {
          attrs: {
            filterable: "",
            remote: "",
            "remote-method": _vm.getOrgList,
            clearabl: "",
            loading: _vm.loading,
            disabled: _vm.disabled,
            placeholder: "请输入组织名称模糊搜索",
          },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.objectCode,
            callback: function ($$v) {
              _vm.objectCode = $$v
            },
            expression: "objectCode",
          },
        },
        _vm._l(_vm.orgNameList, function (item, index) {
          return _c("el-option", {
            key: index,
            attrs: { label: item.orgName, value: item.objectCode },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }