<template>
<div>
  <el-row>
      <el-col :md="20" class="index-config">
        <span>指标配置</span> <span class="total">合计: {{ total }}%</span>
    <el-divider></el-divider>
      </el-col>
    </el-row>
    <div
      v-for="(item, index) in ruleConfigForm"
      :key="index"
      class="rule-config"
      >
      <el-row>
        <el-col :md="10">
          <el-form-item label="选择指标" required>
            <el-select
             @change="handleChange(index)"
             @focus="getAllRuleList"
              remote
              filterable
              clearable
              :remote-method="getRuleList"
              :loading="loading"
              placeholder="请输入指标名称进行模糊搜索"
              v-model="item.indexCode"
              :disabled="disabled"
              suffix-icon="el-icon-search">
              <el-option
                v-for="(item, index) in ruleConfigList"
                :key="index"
                :label="item.indexName"
                :value="item.indexCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="10">
          <el-form-item label="指标权重" required>
            <el-input
              v-model="item.weight"
              type="number"
              max="1"
              min="0"
              :disabled="disabled"
              @change="emitChange"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="!disabled" :md="1" :offset="1" class="add-item">
          <i v-if="index===0" class="el-icon-circle-plus-outline" @click="addItem"></i>
          <i v-else class="el-icon-remove-outline" @click="delItem(index)"></i>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="10">
          <el-form-item label="目标数量" required>
            <el-input
              v-model="item.targetNum"
              type="number"
              min="1"
              @change="emitChange"
              :disabled="disabled"
              placeholder="请输入目标数量"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="10">
          <el-form-item label="单位" required>
            <el-select
              v-model="item.unit"
              @change="emitChange"
              :disabled="disabled"
              placeholder="请选择">
              <el-option label="次" value="次"></el-option>
              <el-option label="家" value="家"></el-option>
              <el-option label="箱" value="箱"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
</div>
</template>
<script>
import request from '../../../../../../utils/request';

export default {
  props: {
    disabled: {
      type: Boolean,
    },
    // 传数组数组对象参数方法
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  watch: {
    value: {
      handler(val) {
        if (val && Array.isArray(val)) {
          this.ruleConfigForm = val;
          if (this.disabled) { // 查看时
            this.ruleConfigForm[this.index].indexCode = val[this.index].indexName;
          }
        }
      },
    },
  },
  data() {
    return {
      // 是否加载
      loading: false,
      // 规则配置
      ruleConfigForm: [
        {
          indexCode: '', // 指标编码
          indexName: '', // 指标名称
          weight: '', // 权重
          targetNum: '', // 目标数量
          unit: '', // 单位
        },
      ],
      // 规则表单索引
      index: 0,
      // 指标列表
      ruleConfigList: [],
    };
  },
  methods: {
    // 添加选项
    addItem() {
      console.log('additem');
      this.ruleConfigForm.push({
        indexName: '', weight: '', targetNum: '', unit: '',
      });
    },
    // 删除选项
    delItem(index) {
      this.ruleConfigForm.splice(index, 1);
    },
    // 选择规则后
    handleChange(index) {
      this.index = index;
      this.ruleConfigList.forEach((item) => {
        if (item.indexCode === this.ruleConfigForm[index].indexCode) {
          this.ruleConfigForm[index].indexName = item.indexName;
        }
      });
      this.$emit('input', this.ruleConfigForm);
    },
    getRuleList(query) {
      this.loading = true;
      // 远程搜索;
      // 获取规则列表;
      request.post('/sfa/SfaIndexController/list', {
        indexName: query,
      }).then((res) => {
        this.loading = false;
        this.ruleConfigList = res.result.data.map((item) => ({
          indexCode: item.indexCode,
          indexName: item.indexName,
        }));
      });
    },
    // 获取所有规则
    getAllRuleList() {
      request.post('/sfa/SfaIndexController/list').then((res) => {
        this.ruleConfigList = res.result.data.map((item) => ({
          indexCode: item.indexCode,
          indexName: item.indexName,
        }));
      });
    },
    emitChange() {
      this.$emit('input', this.ruleConfigForm || []);
    },
  },
  computed: {
    // 合计
    total() {
      let total = 0;
      this.ruleConfigForm.forEach((item) => {
        if (item.weight !== '') {
          total += parseFloat(item.weight) * 100;
        }
      });
      return Math.floor(total) || 0; // fix: 10.00000001%
    },
  },
};
</script>
<style lang="less" scoped>
  .rule-config {
    /deep/ .el-form-item__label {
      font-size: 12px;
    }
    margin-bottom: 12px;
    .el-col {
    .el-icon-remove-outline {
      margin-left: -30px;
      color: #F56C6C;
      font-size: 24px;
    }
    .el-icon-circle-plus-outline {
        margin-left: -30px;
        color: #409EFF;
        font-size: 24px;
      }
    }
  }
  .el-input i {
    font-size: 18px;
    color: #409EFF;
    cursor: pointer;
  }
  .el-form-item .weight {
    text-align: center;
  }
  // .index-config {
  //   margin-bottom: 20px;
  //   border-bottom: 1px dashed #ccc;
  // }
  .total {
    margin-left: 45px;
  }
</style>
