var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-info" },
    _vm._l(_vm.userInfo, function (item, index) {
      return _c(
        "el-row",
        { key: index },
        [
          _c(
            "el-col",
            { attrs: { md: 10 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "人员姓名", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        remote: "",
                        filterable: "",
                        "remote-method": function (query) {
                          return _vm.getUserList(query, "search")
                        },
                        loading: _vm.loading,
                        placeholder: "请输入人员姓名模糊搜索",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleChange(index)
                        },
                        focus: function ($event) {
                          return _vm.getUserList("")
                        },
                      },
                      model: {
                        value: _vm.userInfo[index].objectName,
                        callback: function ($$v) {
                          _vm.$set(_vm.userInfo[index], "objectName", $$v)
                        },
                        expression: "userInfo[index].objectName",
                      },
                    },
                    _vm._l(_vm.userList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.objectName,
                          value: item.objectName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 10 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "人员职位" } },
                [
                  _c("el-input", {
                    staticClass: "position-name",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.userInfo[index].objectPosName,
                      callback: function ($$v) {
                        _vm.$set(_vm.userInfo[index], "objectPosName", $$v)
                      },
                      expression: "userInfo[index].objectPosName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "add-item", attrs: { md: 1, offset: 1 } },
            [
              index === 0
                ? _c("i", {
                    staticClass: "el-icon-circle-plus-outline",
                    on: { click: _vm.addItem },
                  })
                : _c("i", {
                    staticClass: "el-icon-remove-outline",
                    on: {
                      click: function ($event) {
                        return _vm.delItem(index)
                      },
                    },
                  }),
            ]
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }