<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';

import OrgName from './components/org_name.vue';
import UserInfo from './components/user_info.vue';
import RuleConfig from './components/rule_config.vue';

formCreate.component('orgName', OrgName);
formCreate.component('userInfo', UserInfo);
formCreate.component('ruleConfig', RuleConfig);

export default {
  extends: Form,
  data() {
    return {
      buttons: {
        submit: true,
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  methods: {
    /** @desc 详情 */
    setDetail() {

    },
    /** @override 覆盖提交事件 */
    submit() {
      // 判断是否填完
      const formData = this.getFormData();
      if (!formData) return;
      // console.log(formData);
      // 参数设置
      let objectList = [];
      // 删除多余字段
      if (formData.basic === null) delete formData.basic;
      if (formData.ruleConfig1 === null) delete formData.ruleConfig1;
      // 设置objectList字段
      if (formData.applyName === '组织') {
        objectList = [formData.OrgName];
      } else { // 员工
        objectList = formData.objectList;
      }
      if (formData.achievementType === 'FT') { // 自由时间 选择自由时间后
        formData.achievementDateYear = '';
        formData.achievementDateMonth = '';
        formData.achievementDateTenDays = '';
      }
      // if (formData.applyName) delete formData.applyName;
      for (const key in formData) {
        // 后台接口报错，无法正确解析null
        if (formData[key] === null) {
          formData[key] = '';
        }
      }
      delete formData.OrgName;
      const requestParam = {
        ...formData,
        // ...formData.OrgName,
        indexReqVos: formData.indexReqVos || [],
        objectList,
        beginDate: formData.dateRange[0],
        endDate: formData.dateRange[1],
      };
      delete requestParam.dateRange;
      // 区分是编辑还是新增
      let requestUrl = '/sfa/SfaAchievementRuleController/save';
      if (this.formConfig.row && this.formConfig.row.id) {
        requestUrl = '/sfa/sfaIntegralRule/updateRule';
        formData.id = this.formConfig.row.id;
      }
      request.post(requestUrl, requestParam).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功!');
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @desc 字段显示隐藏
     */
    switchField(field) {
      if (field.achievementType === 'MM') { // 月度
        // 隐藏旬度和自由时间字段
        this.hiddenFields(true, ['achievementDateTenDays', 'dateRange']);
        // 显示年月度
        this.hiddenFields(false, ['achievementDateMonth', 'achievementDateYear']);
      } else if (field.achievementType === 'TD') { // 旬度
        // 显示年月旬度
        this.hiddenFields(false, ['achievementDateMonth', 'achievementDateYear', 'achievementDateTenDays']);
        // 隐藏自由时间
        this.hiddenFields(true, 'dateRange');
      } else if (field.achievementType === 'FT') { // 自由时间
        // 显示自由时间 隐藏年月旬度字段
        this.hiddenFields(false, 'dateRange');
        this.hiddenFields(true, ['achievementDateTenDays', 'achievementDateMonth', 'achievementDateYear']);
      }
      if (field.applyName === '组织') {
        // 隐藏人员
        this.hiddenFields(true, 'objectList');
        // 显示组织
        this.hiddenFields(false, 'OrgName');
      } else if (field.applyName === '员工') {
        // 显示人员
        this.hiddenFields(false, 'objectList');
        // 隐藏组织
        this.hiddenFields(true, 'OrgName');
      }
    },
    handleFieldChange() {
      // 绩效类型月度
      const field = this.fApi.form;
      this.switchField(field);
    },
  },
  /** @desc 重新赋值rule */
  async created() {
    const rule = await this.getFormRule('sfaCenter_performanceManage_performanceRuleTable');
    // 默认隐藏自由时间、旬度、人员姓名、人员职位
    this.hiddenFields(true, ['dateRange', 'achievementDateTenDays', 'objectList']);
    this.buttons.submit = !this.formConfig.disabled;
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'dateRange') {
        v.props = {
          ...v.props,
          type: 'daterange',
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
        };
      }
    });
    this.rule = rule;
    this.reload(this.rule);
    // 查看详情
    if (this.formConfig.disabled) { // 查看
      this.switchField(this.formConfig.row);
      this.setValue({
        achievementType: this.formConfig.row.achievementType,
        // ...this.formConfig.row,
        // 组织
        // achievementDateYear: '2030',
        OrgName: {
          objectCode: this.formConfig.row.objectCode, // 对象编码
          objectName: this.formConfig.row.objectName, // 对象名称
        },
        indexReqVos: [{
          // ...this.formConfig.row,
          indexCode: this.formConfig.row.indexCode,
          indexName: this.formConfig.row.indexName,
          weight: this.formConfig.row.weight,
          unit: this.formConfig.row.unit,
          targetNum: this.formConfig.row.targetNum,
        }],
      });
    }
  },
};
</script>
<style lang="less" scoped>
   /deep/ .el-date-editor .el-range-separator {
    width: 7%;
  }
</style>
